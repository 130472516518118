.quiz-text {
    padding: 0 10px;
    text-align: center;
    font-size: 17px;
    font-weight: 600;
    width: 89%;
    flex: 1;
    position: relative;
    z-index: 1;
}

.quiz-text .quiz-btn-container {
    width: 100%;
    padding-top: 19px;
    padding-bottom: 0;
}

.quiz-text.small {
    font-size: 16px;
    font-weight: 400;
}