.quiz-gameplay-preview {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    background-color: #c49ba0;
    user-select: none;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

.quiz-gameplay-preview.hidden {
    opacity: 0;
    z-index: 0;
}

.quiz-gameplay-preview-video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    max-width: 500px;
    position: absolute;
}
.quiz-gameplay-preview-interaction-area {
    background-color: rgba(0, 0, 0, 0);
    width: 100%;
    height: 100%;
    max-width: 500px;
    z-index: 1;
    position: relative;
}

.quiz-gameplay-preview-loading-img {
    position: absolute;
    top: 0;
    left: 50%;
    width: 500px;
    height: 100%;
    pointer-events: none;
    object-fit: cover;
    z-index: 1;
    filter: blur(10px);
    transform: translateX(-250px);
}
.quiz-gameplay-preview-loading-text {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    text-align: center;
    color: white;
}

.quiz-gameplay-preview + .quiz-text {
    margin-top: 10px;
}

.quiz-gameplay-preview + .quiz-btn-container {
    position: absolute;
    bottom: 20px;
    z-index: 1;
}
.quiz-gameplay-preview + .quiz-btn-container .quiz-btn {
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.7);
}

.quiz-gameplay-preview + .quiz-text {
    position: absolute;
    bottom: 20px;
    z-index: 1;
    background-color: #e9f1f6;
    border: 2px solid #b6c1dc;
    border-radius: 20px;
    max-width: 350px;
}