
.quiz-options {
    width: 100%;
    padding-bottom: 0;
    flex: 1;
}
.quiz-options-container {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.quiz-options-container .title {
    width: 89%;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    margin: 0 auto 11px;
}

.quiz-options-container > ul {
    list-style: none;
}

.quiz-options-container > ul > li {
    cursor: pointer;
    margin: auto;
    width: 83%;
    padding: 12px 13px;
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    background-color: #FFFFFF;
    border: 1px solid #CCD7F4;
    border-radius: 12px;
    outline: 0 solid #CCD7F4;
    transition-property: outline-width, border-color;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(.18,.89,.32,1.05);
}

.quiz-options-container > ul > li.active {
    border-color: #1790FF;
    font-weight: 600;
    outline-width: 1px;
}

.quiz-options-container > ul > li + li {
    margin-top: 10px;
}