* {
  font-family: 'Montserrat', sans-serif;
  background-repeat: no-repeat!important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.gc-fullscreen-btn {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000;
}
.gc-game-preview {
  width: 100vw;
  height: 100vh;
  display: flex;           /* establish flex container */
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;     /* center items horizontally, in this case */
}
.gc-game-preview .preview-image {
  width: 100vw;
  height: 89vh;
  position: relative;
}
.gc-game-preview .preview-image .image-container {
  width: 78vw;
  position: relative;
  z-index: 1;
  top: 6vh;
  left: 11vw;
  overflow: hidden;
  display: flex;           /* establish flex container */
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;     /* center items horizontally, in this case */
  height: 78vh;
}
.gc-game-preview .preview-image .image-container .image {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  display: block;
  border-radius: 4vmin;
}
.gc-game-preview .preview-image .image-blurred {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  filter: blur(10px);
}

.gc-game-preview .btn-container {
  line-height: 12vh;
  text-align: center;
  background: #807EB0;
  width: 100vw;
  height: 11vmax;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  position: relative;
}
.gc-game-preview .btn-container .app-icon {
  width: 9vmax;
  height: 9vmax;
  margin: 2.1vmax 2.5vmax;
  vertical-align: middle;
  border-radius: 10vmin;
}
.gc-game-preview .btn-container .btn {
  font-size: 6vmin;
  line-height: 120%;
  background: #F43D1E;
  width: 50vmax;
  height: 8vmax;
  padding-top: 2.8vmin;
  margin-right: 3vmax;
  color: white;
  text-decoration: none;
  border-radius: 10vmin;
  display: flex;           /* establish flex container */
  flex-direction: column;  /* make main axis vertical */
  justify-content: center; /* center items vertically, in this case */
  align-items: center;     /* center items horizontally, in this case */
}
.gc-game-preview .btn-container .btn .text {
  
}