.quiz-email-form {
    width: 84%;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
}

.quiz-email-form-text {
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    padding: 0 17px;
}

.quiz-email-form-input {
    width: 100%;
    height: 50px;
    color: #21202C;
    font-size: 22px;
    font-weight: 700;
    line-height: 26.82px;
    border: 1px solid #ACBCE5;
    border-radius: 13.08px;
    margin: 10px 0 0;
    padding: 0 17px;
}

.quiz-email-form-input:focus {
    outline: 0;
    border-color: #1790FF;
}

.quiz-email-form-input::placeholder {
    color: #ACBCE5;
}

.quiz-email-form-input.valid {
    border-color: #00D160;
}

.quiz-email-form-input.invalid {
    border-color: #F00E00;
}

.quiz-email-form-validation-text {
    opacity: 0;
    font-weight: 400;
    font-size: 11px;
    color: #F00E00;
    padding: 3px 0;
}

.quiz-email-form-input.invalid + .quiz-email-form-validation-text {
    opacity: 1;
    transition: opacity 0.3s ease-in;
}

.quiz-email-form-details {
    color: #7D88A4;
    font-size: 13px;
    font-weight: 500;
    line-height: 15.85px;
    text-align: center;
    padding: 3px 16px 11px;
}

.quiz-email-form .quiz-btn-container {
    width: 100%;
}