
.quiz-text .quiz-btn-container {
    width: 100%;
}

.quiz-btn-container {
    width: 84%;
    z-index: 1;
}

.quiz-btn {
    cursor: pointer;
    width: 100%;
    background-color: #00D160;
    transition-property: transform, box-shadow;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(.18,.89,.32,1.05);
    transform: scale(1);
    color: white;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    padding: 18px 18px 21px;
    border-radius: 13.08px;
    box-shadow: 0 -6.92248010635376px 0 0 #00B132 inset;

}

.quiz-btn:active {
    box-shadow: 0 -3.92248010635376px 0 0 #00B132 inset;
    transform: scale(0.98);
}
