.major-btn {
    cursor: pointer;
    width: 100%;
    background-color: #00D160;
    transition-property: transform, box-shadow;
    transition-duration: 0.15s;
    transition-timing-function: cubic-bezier(.18,.89,.32,1.05);
    transform: scale(1);
    color: white;
    font-size: 22px;
    font-weight: 700;
    text-align: center;
    line-height: 24px;
    text-transform: uppercase;
    padding: 18px 18px 21px;
    border-radius: 13.08px;
    box-shadow: 0 -6.92248010635376px 0 0 #00B132 inset;
    
    transition: all 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28);
    animation: pulse 0.7s linear infinite;
    margin-bottom: 23px;
}

.major-btn:active {
    box-shadow: 0 -3.92248010635376px 0 0 #00B132 inset;
    transform: scale(0.98);
}


.major-btn.hidden {
    margin-bottom: -74px;
    opacity: 0;
}

.major-btn-text {
    font-size: 26px;
    line-height: 29px;
}


@keyframes pulse {
    0% {
        transform: scale(1);
    }

    28% {
        transform: scale(1);
    }

    45% {
        transform: scale(1.03);
    }

    100% {
        transform: scale(1);
    }
}