.quiz-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
    overscroll-behavior: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.quiz-container {;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}

.quiz-frame {
    width: 85%;
    height: 633px;
    min-width: 350px;
    max-width: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 27px;
    background: #EDF2FF;
    border-radius: 22px;
    box-shadow: 0 3.3962361812591553px 20.377416610717773px 0 #00034740;
}

.screens-container {
    position: relative;
    width: 100%;
    height: 100%;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-end;
}

.screen {
    position: absolute;
    width: 375px;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: flex-start;
    animation: slide-in 0.25s cubic-bezier(0.18, 0.89, 0.32, 1.28);
}

.screen.scrollable {
    overflow-y: scroll;
}

.screen.full {
    width: 100%;
}

@keyframes slide-in {
    from {
        transform: translate(0, 100px);
        opacity: 0;
    }

    to {
        transform: translate(0, 0);
        opacity: 1;
    }
}

.blur-overlay {
    pointer-events: all;
    backdrop-filter: blur(10px);
    background-color: rgba(69, 44, 99, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transition: all 0.25s linear;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.blur-overlay.hidden {
    pointer-events: none;
    backdrop-filter: none;
    opacity: 0;
}

.blur-overlay .loader {
    color: #fff;
    font-size: 25px;
    text-align: center;
    line-height: 32px;
}

.blur-overlay .loader img {
    animation: pulse 0.7s linear infinite;
    width: 100px;
}

@keyframes appear-slide {
    0% {
        opacity: 0;
        transform: scale(.95) translateY(20px);
    }
    100% {
        opacity: 1;
        transform: scale(1) translateX(0);
    }
}

.animation-delay-0 {
    animation-delay: 0ms !important;
}

.animation-delay-1 {
    animation-delay: 400ms !important;
}

.animation-delay-2 {
    animation-delay: 800ms !important;
}

.animation-delay-3 {
    animation-delay: 1200ms !important;
}

.animation-delay-4 {
    animation-delay: 1600ms !important;
}

.animation-delay-5 {
    animation-delay: 2000ms !important;
}

.animation-appear-slide {
    opacity: 0;
    transform: scale(.95) translateY(20px);
    animation: appear-slide 500ms cubic-bezier(.18,.89,.32,1.05) forwards;
}

.animation-disappear-slide {
    opacity: 1;
    transform: scale(1) translateX(0);
    animation: appear-slide 500ms cubic-bezier(.18,.89,.32,1.05) forwards reverse;
}

.animation-disappear-slide,
.animation-disappear-opacity {
    animation-delay: 0ms !important;
}

.animation-appear-opacity {
    opacity: 0;
    animation: appear-opacity 1800ms cubic-bezier(.18,.89,.32,1.05) forwards;
}

.animation-disappear-opacity {
    opacity: 1;
    animation: disappear-opacity 500ms cubic-bezier(.18,.89,.32,1.05) forwards;
}

@keyframes appear-opacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}



@keyframes disappear-opacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}