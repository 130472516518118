

.crosshair-interaction-container {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
    /*background-color: rgba(255, 0, 0, .3);*/

    display: flex;
    justify-content: center;
    align-items: center;
}

.crosshair-collider {
    /*border: 1px solid red;*/
    position: relative;
    transition: all 0.15s ease-in;
}

.crosshair {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 40px;
    height: 40px;
    margin-top: -20px;
    margin-left: -20px;
}
