
.back-btn {
    cursor: pointer;
    width: 24px;
    height: 24px;
    border-radius: 7.47px;
    background: #F3F6FC;
    box-shadow: 0 2.7169888019561768px 8.558514595031738px 0 #1C48E429;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: scale(1);
    transition-property: transform, opacity, box-shadow;
    transition-duration: 0.25s;
    transition-timing-function: cubic-bezier(.18,.89,.32,1.05);
}

.back-btn:active {
    transform: scale(0.95);
    box-shadow: 0 3.7169888019561768px 11.558514595031738px 0 #1C48E411;
}

.back-btn.hidden {
    opacity: 0;
    pointer-events: none;
}

.back-btn-image {
    width: 15px;
    height: 15px;
    transform: translateX(-1px);
}