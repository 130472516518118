.progress-bar-container {
    display: flex;
    align-items: center;
    width: 83%;
    margin: 17px 0 8px;
    z-index: 2;
}

.progress-bar-container.hidden {
    visibility: hidden;
}

.progress-bar {
    width: 72%;
    height: 7px;
    margin: 0 auto;
    position: relative;
    background: #DEE5E1;
    overflow: hidden;
    border-radius: 29.21px;
}


.progress-bar-filled {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    background: #00D160;
    z-index: 0;
    transition: width 0.5s cubic-bezier(.18,.89,.32,1.05);
}

.progress-bar-text {
    font-size: 15px;
    font-weight: 500;
}
.progress-bar-text .highlighted {
    font-weight: 700;
}