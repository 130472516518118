.quiz-tutorial {
    position: absolute;
    top: 0;
    left: 0;
    padding-top: 40px;
    width: 100%;
    height: 100%;
    z-index: 2;
    background-color: rgba(34, 49, 73, 0.75);
    pointer-events: none;
    user-select: none;
    display: flex;
    justify-content: center;
    align-content: center;
    overflow: hidden;
    transition: background-color 0.25s linear;
}

.quiz-tutorial-content {
    margin: auto;
}

.quiz-tutorial-example {
    padding-bottom: 20px;
    position: relative;
    /*outline: 1px solid red;*/
}
.quiz-tutorial-example-hand {
    width: 100px;
    height: 100px;
    position: absolute;
    top: -50px;
    left: -50px;
    /*transform: translateX(15px) translateY(15px);*/
    /*outline: 1px solid red;*/
}
.quiz-tutorial-example-image {
    width: 150px;
    height: 75px;
    position: relative;
    z-index: 2; /* DEV: set to 2 */
    /*z-index: 0; !* DEV: set to 2 *!*/
}
.quiz-tutorial-example-svg {
    width: 150px;
    height: 75px;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
}
.quiz-tutorial-text {
    display: block;
    text-align: center;
    width: 150px;
    font-size: 24px;
    font-weight: bold;
    color: white;
}